import React, { lazy } from 'react';
import { Typography } from '@mui/material';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import LIB from '../helper/lib';
import ContainerVF from '../element/ContainerVF';
import LazyFallback from '../element/LazyFallback';
import ButtonVF from '../element/ButtonVF';
import { SERVER_URL } from '../data/app-info';
import userState from '../context/user';

const FormVF = lazy(() =>
    import(/* webpackChunkName: "FormVF" */ '../element/FormVF')
);

function Signin({ accountToggle }) {
    const signin = async (result) => {
        const userInfo = new FormData();
        userInfo.append('email', result.email.value);
        userInfo.append('password', result.password.value);

        const output = await LIB.getFetch(`${SERVER_URL}/get_user`, userInfo);

        if (output && output.success) {
            if (output.result) {
                navigate('?thank-you-signin');
                userState.setInfo(output.result);

                accountToggle(false);
                return { success: null };
            }
            return {
                success: false,
                message: 'No account found. Please register first.'
            };
        }

        return {
            success: false,
            message: 'Username / password did not match.'
        };
    };

    return (
        <React.Suspense fallback={<LazyFallback />}>
            <ContainerVF maxWidth='sm'>
                <Typography variant='h2'>Sign in</Typography>
            </ContainerVF>

            <FormVF
                maxWidth='xs'
                category='Sign In'
                buttonText='Sign in'
                submitFunction={signin}
                elements={{
                    email: {
                        title: 'Email',
                        type: 'email',
                        value: null
                    },
                    password: {
                        title: 'Password',
                        type: 'password',
                        min: 5
                    }
                }}
            >
                <div>
                    <ButtonVF
                        border={false}
                        size='sm'
                        variant='secondary'
                        vfstat={{
                            category: 'Account',
                            action: 'Reset/Forgot Password'
                        }}
                        aria-label='forgetPassword'
                        onClick={() => {
                            userState.set('type', 'reset');
                        }}
                    >
                        Change Password
                    </ButtonVF>
                </div>

                <ButtonVF
                    border={false}
                    size='sm'
                    variant='secondary'
                    vfstat={{
                        category: 'Account',
                        action: 'Register'
                    }}
                    aria-label='register'
                    onClick={() => {
                        userState.set('type', 'register');
                    }}
                >
                    Not a member? Register
                </ButtonVF>
            </FormVF>
        </React.Suspense>
    );
}

Signin.defaultProps = {
    accountToggle: null
};

Signin.propTypes = {
    accountToggle: PropTypes.func
};

export default Signin;
